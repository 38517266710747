'use strict';

/**
 * Set color background of tile
 */
function applyBgColor() {
    $('.tile-container').each(function () {
        var bgColor = $(this).attr('data-bg-color');

        if (bgColor) {
            $(this).css('background-color', bgColor);
        }
    });
}

$(document).ready(function () {
    applyBgColor();
});

$(window).smartresize(function () {
    applyBgColor();
});
